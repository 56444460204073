import { RegionTypes } from "./region.types";

const INITIAL_STATE = {
  region: "",
};

const regionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RegionTypes.SET_REGION:
      return { ...state, region: action.payload };
    default:
      return state;
  }
};

export default regionReducer;

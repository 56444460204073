import { OverviewTypes } from "./overview.types";

const INITIAL_STATE = {
  analyticsData: [],
  prevSaved: "",
  currSaved: "",
};

export const generateSearchData = (state, payload, replace) => {
  const {
    data,
    replacement,
    filter: { start_date, end_date, timeStamp, currency },
  } = payload;

  if (replace) {
    const temp = [...state.analyticsData];

    const rep = temp.findIndex((el) => {
      return (
        el.filter.start_date === replacement.start_date &&
        el.filter.end_date === replacement.end_date &&
        el.filter.currency === replacement.currency &&
        el.filter.timeStamp === replacement.timeStamp
      );
    });

    temp[rep] = {
      filter: { start_date, end_date, timeStamp, currency },
      data,
    };
    return temp;
  }

  return [
    ...state.analyticsData,
    { filter: { start_date, end_date, timeStamp, currency }, data },
  ];
};
const overviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OverviewTypes.SET_OVERVIEW_DATA: {
      let overviewData;

      if (state.analyticsData?.length < 2) {
        overviewData = generateSearchData(state, action.payload);
      }

      if (state.analyticsData?.length === 2) {
        overviewData = generateSearchData(state, action.payload, true);
      }

      return {
        ...state,
        analyticsData: overviewData ?? [],
      };
    }

    case OverviewTypes.SET_CURR_SAVED: {
      return { ...state, currSaved: action.payload };
    }

    case OverviewTypes.SET_PREV_SAVED: {
      return { ...state, prevSaved: action.payload };
    }
    default:
      return state;
  }
};

export default overviewReducer;

import { DateTypes } from "./date.types";

const INITIAL_STATE = {
  start_date: "",
  end_date: "",
  timeFrame: "",
};

const dateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DateTypes.SET_START_DATE:
      return {
        ...state,
        start_date: action.payload,
      };
    case DateTypes.SET_END_DATE:
      return {
        ...state,
        end_date: action.payload,
      };

    case DateTypes.SET_TIME_FRAME: {
      const { timeFrame } = action.payload;
      return {
        ...state,
        timeFrame: timeFrame,
      };
    }
    default:
      return state;
  }
};

export default dateReducer;

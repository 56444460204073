import { SidebarTypes } from "./sidebar.types";

const INITIAL_STATE = {
  sidebarShow: "responsive",
};

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SidebarTypes.SET_STATE:
      return { ...state, sidebarShow: action.payload };
    default:
      return state;
  }
};

export default sidebarReducer;

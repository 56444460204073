import { AnalyticsGeographyTypes } from "./analyticsGeography.types";
import { generateAnalyticsData } from "../utils";

const INITIAL_STATE = {
  analyticsData: [],
  prevSaved: "",
  currSaved: "",
};

const analyticsGeographyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AnalyticsGeographyTypes.SET_ANALYTICS_GEOGRAPHY_DATA: {
      let analyticsGeographyData;

      if (state.analyticsData?.length < 2) {
        analyticsGeographyData = generateAnalyticsData(state, action.payload);
      }

      if (state.analyticsData?.length === 2) {
        analyticsGeographyData = generateAnalyticsData(
          state,
          action.payload,
          true
        );
      }

      return {
        ...state,
        analyticsData: analyticsGeographyData ?? [],
      };
    }

    case AnalyticsGeographyTypes.SET_CURR_SAVED: {
      return { ...state, currSaved: action.payload };
    }

    case AnalyticsGeographyTypes.SET_PREV_SAVED: {
      return { ...state, prevSaved: action.payload };
    }
    default:
      return state;
  }
};

export default analyticsGeographyReducer;

import { SearchConsoleTypes } from "./searchConsole.types";
import { generateAnalyticsData } from "../utils";

const INITIAL_STATE = {
  analyticsData: [],
  prevSaved: "",
  currSaved: "",
};

const searchConsoleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchConsoleTypes.SET_SEARCH_CONSOLE_DATA: {
      let searchConsoleData;

      if (state.analyticsData?.length < 2) {
        searchConsoleData = generateAnalyticsData(state, action.payload);
      }

      if (state.analyticsData?.length === 2) {
        searchConsoleData = generateAnalyticsData(state, action.payload, true);
      }

      return {
        ...state,
        analyticsData: searchConsoleData ?? [],
      };
    }

    case SearchConsoleTypes.SET_CURR_SAVED: {
      return { ...state, currSaved: action.payload };
    }

    case SearchConsoleTypes.SET_PREV_SAVED: {
      return { ...state, prevSaved: action.payload };
    }
    default:
      return state;
  }
};

export default searchConsoleReducer;

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dateReducer from "./date/date.reducer";
import sidebarReducer from "./sidebar/sidebar.reducer";
import countryReducer from "./country/country.reducer";
import regionReducer from "./region/region.reducer";
import currencyReducer from "./currency/currency.reducer";
import overviewReducer from "./overview/overview.reducer";
import productReducer from "./products/product.reducer";
import orderReducer from "./orders/orders.reducer";
import searchConsoleReducer from "./searchConsole/searchConsole.reducer";
import analyticsOverviewReducer from "./analyticsOverview/analyticsOverview.reducer";
import analyticsPageReportReducer from "./analyticsPageReport/analyticsPageReport.reducer";
import analyticsGeographyReducer from "./analyticsGeography/analyticsGeography.reducer";
import analyticsAcquisitionReducer from "./analyticsAcquisition/analyticsAcquisition.reducer";
import combinationsReducer from "./combinations/combinations.reducer";

const persistConfig = {
  key: "dates",
  storage,
};

const rootReducer = combineReducers({
  dateReducer,
  sidebarReducer,
  countryReducer,
  regionReducer,
  currencyReducer,
  overviewReducer,
  productReducer,
  orderReducer,
  searchConsoleReducer,
  analyticsOverviewReducer,
  analyticsPageReportReducer,
  analyticsGeographyReducer,
  analyticsAcquisitionReducer,
  combinationsReducer,
});

export default persistReducer(persistConfig, rootReducer);

export const generateAnalyticsData = (state, payload, replace) => {
  const {
    data,
    replacement,
    filter: { start_date, end_date, timeStamp },
  } = payload;

  if (replace) {
    const temp = [...state.analyticsData];

    const rep = temp.findIndex(
      (el) =>
        el.filter.start_date === replacement.start_date &&
        el.filter.end_date === replacement.end_date &&
        el.filter.timeStamp === replacement.timeStamp
    );

    temp[rep] = {
      filter: { start_date, end_date, timeStamp },
      data,
    };
    return temp;
  }

  return [
    ...state.analyticsData,
    { filter: { start_date, end_date, timeStamp }, data },
  ];
};

export const generateData = (state, payload, replace) => {
  const {
    data,
    replacement,
    filter: { start_date, end_date, currency, country, region, timeStamp },
  } = payload;

  if (replace) {
    const temp = [...state.storeData];

    const rep = temp.findIndex(
      (el) =>
        el.filter.start_date === replacement.start_date &&
        el.filter.end_date === replacement.end_date &&
        el.filter.currency === replacement.currency &&
        el.filter.region === replacement.region &&
        el.filter.country === replacement.country &&
        el.filter.timeStamp === replacement.timeStamp
    );

    temp[rep] = {
      filter: { start_date, end_date, currency, country, region, timeStamp },
      data,
    };
    return temp;
  }

  return [
    ...state.storeData,
    {
      filter: { start_date, end_date, currency, country, region, timeStamp },
      data,
    },
  ];
};

import { ProductsTypes } from "./product.types";

const INITIAL_STATE = {
  productsData: [],
  prevSaved: "",
  currSaved: "",
};

const generateProductsData = (state, payload, replace) => {
  const {
    data,
    replacement,
    filter: {
      start_date,
      end_date,
      currency,
      region,
      country,
      showOnlySold,
      timeStamp,
    },
  } = payload;

  if (replace) {
    const temp = [...state.productsData];

    const rep = temp.findIndex(
      (el) =>
        el.filter.start_date === replacement.start_date &&
        el.filter.end_date === replacement.end_date &&
        el.filter.currency === replacement.currency &&
        el.filter.country === replacement.country &&
        el.filter.region === replacement.region &&
        el.filter.showOnlySold === replacement.showOnlySold &&
        el.filter.timeStamp === replacement.timeStamp
    );

    temp[rep] = {
      filter: {
        start_date,
        end_date,
        currency,
        region,
        country,
        showOnlySold,
        timeStamp,
      },
      data,
    };
    return temp;
  }

  return [
    ...state.productsData,
    {
      filter: {
        start_date,
        end_date,
        currency,
        region,
        country,
        showOnlySold,
        timeStamp,
      },
      data,
    },
  ];
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductsTypes.SET_PRODUCT_DATA: {
      let productsData;

      if (state.productsData?.length < 2) {
        productsData = generateProductsData(state, action.payload);
      }

      if (state.productsData?.length === 2) {
        productsData = generateProductsData(state, action.payload, true);
      }

      return {
        ...state,
        productsData: productsData ?? [],
      };
    }

    case ProductsTypes.SET_CURR_SAVED: {
      return { ...state, currSaved: action.payload };
    }

    case ProductsTypes.SET_PREV_SAVED: {
      return { ...state, prevSaved: action.payload };
    }
    default:
      return state;
  }
};

export default productReducer;

import { CountryTypes } from "./country.types";

const INITIAL_STATE = {
  country: "",
};

const countryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CountryTypes.SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    default:
      return state;
  }
};

export default countryReducer;

import { OrderTypes } from "./orders.types";
import { generateData } from "../utils";
const INITIAL_STATE = {
  storeData: [],
  prevSaved: "",
  currSaved: "",
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderTypes.SET_ORDER_DATA: {
      let ordersData;

      if (state.storeData?.length < 2) {
        ordersData = generateData(state, action.payload);
      }

      if (state.storeData?.length === 2) {
        ordersData = generateData(state, action.payload, true);
      }

      return {
        ...state,
        storeData: ordersData ?? [],
      };
    }

    case OrderTypes.SET_CURR_SAVED: {
      return { ...state, currSaved: action.payload };
    }

    case OrderTypes.SET_PREV_SAVED: {
      return { ...state, prevSaved: action.payload };
    }
    default:
      return state;
  }
};

export default orderReducer;
